import React from "react"
import { graphql, StaticQuery } from "gatsby"

import { Stat } from "./Stat"

export const BoilerStats = ({
  boilerType,
  erpRating,
  kW,
  manufacturerCountry,
  name,
  warranty,
}) => (
  <StaticQuery
    query={graphql`
      query BoilerStats {
        statsExplanations: allContentfulBoilerStatInformationCategories {
          edges {
            node {
              contentful_id
              image {
                fluid {
                  ...GatsbyContentfulFluid
                }
                title
              }
              name
              statExplanation {
                raw
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <section className="background-grey-lightest">
        <div className="wrapper-width wrapper-height stats">
          <h2 className="heading-large">What makes this boiler great?</h2>
          <p className="stats-introduction">
            We only stock outstanding boilers but these reasons are why the{" "}
            {name} stands out:
          </p>
          <section className="stat-container">
            <Stat
              name="Guarantee warranty"
              stat={`${warranty} Years`}
              modalSize="medium"
              id="2MWaKzqF3RflC0jQOn7eud"
              statList={data.statsExplanations.edges}
            />
            <Stat
              name="Energy efficiency"
              stat={`${erpRating} Rated`}
              modalSize="medium"
              id="63Gk4Ic3kvg5vfs8txuSbN"
              statList={data.statsExplanations.edges}
            />
            <Stat
              name="Power"
              stat={`${kW} kW`}
              modalSize="medium"
              id="56JDvE6VseGb2xGwAI1g25"
              statList={data.statsExplanations.edges}
            />
            <Stat
              name="Boiler type"
              stat={`${
                boilerType === "Boiler with tank" ? "Regular" : boilerType
              }`}
              modalSize="medium"
              id="4SOXq917YGtkBjgPvTP38i"
              statList={data.statsExplanations.edges}
            />
            <Stat
              name="Manufactured"
              stat={`${manufacturerCountry}`}
              modalSize="small"
              id="4T4s0H2OhBXPoyp4ngZZTx"
              statList={data.statsExplanations.edges}
            />
          </section>
        </div>
      </section>
    )}
  />
)
