import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import { Modal, RichText } from "../../../Shared"

export const Stat = ({ name, stat, modalSize, id, statList }) => {
  const [infoVisible, setInfoVisibility] = React.useState(false)

  const modalContent = statList.find(({ node }) => node.contentful_id === id)
    .node

  return (
    <>
      <section className="stat">
        <div className="stat-content">
          <h3 className="heading-large">{stat}</h3>
          <button
            className="stat-info-button"
            onClick={() => setInfoVisibility(true)}
          >
            <span className="icon-info"></span>
            <span className="stat-info-button-text">{name}</span>
          </button>
        </div>
      </section>
      <Modal
        isVisible={infoVisible}
        dismissFunc={() => setInfoVisibility(false)}
        heading={name}
        size={modalSize}
      >
        <div className="stat-modal-content">
          {modalContent.image && modalContent.image.fluid && (
            <Img fluid={modalContent.image.fluid} />
          )}
          <RichText content={modalContent.statExplanation} />
        </div>
      </Modal>
    </>
  )
}

Stat.propTypes = {
  name: PropTypes.string.isRequired,
  stat: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  statList: PropTypes.array.isRequired,
  modalSize: PropTypes.string.isRequired,
}
