import React from "react"
import { Link } from "gatsby"

import {
  Card,
  Pill,
  BreakDownTable,
  RangeSlider,
  BulletListWithIcon,
} from "../../Shared"
import { CardSection } from "../../Sections"
import {
  convertPriceToPounds,
  calculateOrderPayments,
  quoteCheckoutURL,
} from "../../../utilities"

export const BoilerFinanceOptions = ({
  price,
  quoteId,
  contentful_id,
  quote,
}) => {
  const [loanTerm, setLoanTerm] = React.useState(24)

  const [boilerFinance, setBoilerFinance] = React.useState(() =>
    calculateOrderPayments({
      boilerAmount: parseInt(price) * 100,
      boilerControlAmount: parseInt(0) * 100,
      code: "finance",
      financeMonths: loanTerm,
      interestRate: quote.interestRate,
      labourCost: parseInt(quote.labourCost) * 100,
      flueCost: parseInt(quote.flueCost) * 100,
      vat: quote.vat,
    })
  )

  const boilerHalfUpfront = calculateOrderPayments({
    boilerAmount: parseInt(price) * 100,
    boilerControlAmount: parseInt(0) * 100,
    code: "50-percent-upfront",
    financeMonths: 12,
    interestRate: 0,
    labourCost: parseInt(quote.labourCost) * 100,
    flueCost: parseInt(quote.flueCost) * 100,
    vat: quote.vat,
  })

  const boilerUpfront = calculateOrderPayments({
    boilerAmount: parseInt(price) * 100,
    boilerControlAmount: parseInt(0) * 100,
    code: "up-front",
    financeMonths: 0,
    interestRate: 0,
    labourCost: parseInt(quote.labourCost) * 100,
    flueCost: parseInt(quote.flueCost) * 100,
    vat: quote.vat,
  })

  React.useEffect(() => {
    setBoilerFinance(() =>
      calculateOrderPayments({
        boilerAmount: parseInt(price) * 100,
        boilerControlAmount: parseInt(0) * 100,
        code: "finance",
        financeMonths: loanTerm,
        interestRate: quote.interestRate,
        labourCost: parseInt(quote.labourCost) * 100,
        flueCost: parseInt(quote.flueCost) * 100,
        vat: quote.vat,
      })
    )
  }, [loanTerm, quote])

  return (
    <CardSection
      heading="Finance options"
      paragraph={`We want to make sure everyone who needs a ${name} gets one! Choose one of the options below when you book your installation.`}
    >
      <Card preHeading="Pay by card" heading="One-off payment">
        <BulletListWithIcon
          bullets={["No loan required", "Everything paid up-front"]}
        />
        <Pill text="Fastest" />
        <div className="loan-term">
          <div className="loan-term-header">
            <span>Loan term</span>
            <span className="loan-term-value">No loan</span>
          </div>
          <span className="loan-term-value-large">No loan</span>
        </div>
        <BreakDownTable
          rows={[
            {
              id: "1",
              label: "Pay now",
              price: convertPriceToPounds(boilerUpfront.upfrontAmount),
            },
            {
              id: "2",
              label: "Loan amount",
              price: 0,
            },
            {
              id: "3",
              label: "Interest",
              price: 0,
            },
            {
              id: "4",
              label: "(Monthly payment)",
              price: 0,
            },
          ]}
          total={convertPriceToPounds(boilerUpfront.upfrontAmount)}
        />
      </Card>
      <Card preHeading="50% up-front" heading="12-month loan">
        <BulletListWithIcon
          bullets={["0% APR Representative", "50% up-front required"]}
        />
        <Pill text="Interest-free" />
        <div className="loan-term">
          <div className="loan-term-header">
            <span>Loan term</span>
            <span className="loan-term-value">12 months</span>
          </div>
          <span className="loan-term-value-large">12 months</span>
        </div>
        <BreakDownTable
          rows={[
            {
              id: "1",
              label: "Pay now",
              price: convertPriceToPounds(boilerHalfUpfront.upfrontAmount),
            },
            {
              id: "2",
              label: "Loan amount",
              price: convertPriceToPounds(boilerHalfUpfront.financeAmount),
            },
            {
              id: "3",
              label: "Interest",
              price: 0,
            },
            {
              id: "4",
              label: "(Monthly payment)",
              price: convertPriceToPounds(boilerHalfUpfront.monthlyRepayment),
            },
          ]}
          total={convertPriceToPounds(boilerHalfUpfront.totalRepayment)}
        />
      </Card>
      <Card preHeading="Pay monthly" heading="24 - 120 month loan">
        <BulletListWithIcon
          bullets={[`${`9.9`}% APR Representative`, "£250 deposit required"]}
        />
        <Pill text="Most affordable" />
        <div className="loan-term">
          <div className="loan-term-header">
            <span>Loan term</span>
            <span className="loan-term-value">{loanTerm} months</span>
          </div>
          <RangeSlider
            value={loanTerm}
            updateValue={(value) => setLoanTerm(value)}
            minValue={24}
            maxValue={120}
          />
        </div>
        <BreakDownTable
          rows={[
            {
              id: "1",
              label: "Pay now",
              price: convertPriceToPounds(boilerFinance.upfrontAmount),
            },
            {
              id: "2",
              label: "Loan amount",
              price: convertPriceToPounds(boilerFinance.financeAmount),
            },
            {
              id: "3",
              label: "Interest",
              price: convertPriceToPounds(boilerFinance.financeCharge),
            },
            {
              id: "4",
              label: "(Monthly payment)",
              price: convertPriceToPounds(boilerFinance.monthlyRepayment),
            },
          ]}
          total={convertPriceToPounds(boilerFinance.totalRepayment)}
        />
      </Card>
      {quoteId && (
        <div className="card-section-button">
          <Link
            to={quoteCheckoutURL(quoteId, contentful_id)}
            className="button-primary"
          >
            Book your installation
          </Link>
        </div>
      )}
    </CardSection>
  )
}
