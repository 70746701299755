import React from "react"
import { graphql, Link } from "gatsby"
import axios from "axios"

import {
  BoilerBlob,
  BulletListWithIcon,
  FooterMain,
  Loader,
  LoaderContainer,
  NavMinimal,
  Seo,
  SubNav,
} from "../components/Shared"
import { YourInstallation } from "../components/Sections"
import { BoilerFinanceOptions, BoilerStats } from "../components/BoilerPage"
import { quoteResultURL, quoteCheckoutURL } from "../utilities"

const BoilerPage = ({ data }) => {
  const {
    award,
    boilerType,
    erpRating,
    features,
    contentful_id,
    kW,
    price,
    manufacturerCountry,
    name,
    photo,
    tagline,
    warranty,
    seo,
    slug,
  } = data.contentfulBoilers

  const [quoteId, setQuoteId] = React.useState(null)
  const [quote, setQuote] = React.useState({
    flueCost: 0,
    interestRate: 0,
    labourCost: 0,
    vat: 0,
    moreInfoNeeded: true,
  })
  const [loader, setLoader] = React.useState({
    loading: true,
    error: false,
  })

  const getQuoteDetails = async (localQuoteId) => {
    setLoader({
      loading: true,
      error: false,
    })

    try {
      const getQuote = await axios({
        method: "get",
        url: `${process.env.GATSBY_API_BASE_URL}/quote/${localQuoteId}`,
      })
      setQuote(getQuote.data)
      setLoader({
        loading: false,
        error: false,
      })
    } catch (error) {
      setLoader({
        loading: false,
        error: true,
      })
    }
  }

  React.useEffect(() => {
    if (localStorage.getItem("quoteId")) {
      setQuoteId(localStorage.getItem("quoteId"))
      getQuoteDetails(localStorage.getItem("quoteId"))
    }
  }, [])

  return (
    <>
      <NavMinimal />
      <Seo
        url={data.site && data.site.siteMetadata.url + "/boilers/" + slug}
        title={seo && seo.title}
        description={seo.metaDescription && seo.metaDescription.metaDescription}
        image={seo && seo.image && seo.image.file && seo.image.file.url}
      />
      {quoteId && !quote.moreInfoNeeded && (
        <SubNav>
          <Link to={quoteResultURL(quoteId)} className="sub-nav-back-link">
            <span className="icon-arrow-left"></span>
            <span className="sub-nav-item">Back to quote results</span>
          </Link>
        </SubNav>
      )}
      <header className="wrapper-width grid-col-2 header">
        <div className="header-left">
          <BoilerBlob
            blobColor="yellow"
            photo={photo.fluid}
            photoDesc={photo.title}
            award={award && award.fluid ? award.fluid : ""}
            awardDesc={award && award.title ? award.title : ""}
          />
        </div>
        <div className="header-right">
          <h1 className="heading-extra-large">{`${name}`}</h1>
          <span className="view-boiler-tagline">{tagline}</span>
          <BulletListWithIcon bullets={features} />
          <div className="header-buttons">
            {quoteId && !quote.moreInfoNeeded ? (
              <Link
                to={quoteCheckoutURL(quoteId, contentful_id)}
                className="button-primary"
              >
                Book your installation
              </Link>
            ) : (
              <Link to="/quote" className="button-primary">
                Get your fixed quote
              </Link>
            )}
          </div>
        </div>
      </header>
      <BoilerStats
        name={name}
        warranty={warranty}
        erpRating={erpRating}
        kW={kW}
        boilerType={boilerType}
        manufacturerCountry={manufacturerCountry}
      />
      <YourInstallation
        quoteId={quoteId}
        boilerId={contentful_id}
        moreInfoNeeded={quote.moreInfoNeeded}
        orderExtras={
          quote.orderExtras ? quote.orderExtras : data.extras.orderExtras
        }
      />
      {quoteId &&
        !quote.moreInfoNeeded &&
        (loader.loading ? (
          <LoaderContainer>
            <Loader
              text="Finding quote finance information"
              error={loader.error}
              errorText="Could not find quote finance information"
            />
          </LoaderContainer>
        ) : (
          <BoilerFinanceOptions
            price={price}
            quoteId={quoteId}
            contentful_id={contentful_id}
            quote={quote}
          />
        ))}
      <footer className="wrapper-width footer">
        <FooterMain />
      </footer>
    </>
  )
}

export default BoilerPage

export const pageQuery = graphql`
  query getBoilerDetails($id: String!) {
    site {
      siteMetadata {
        url
      }
    }
    contentfulBoilers(contentful_id: { eq: $id }) {
      award {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid
        }
        title
      }
      id
      boilerType
      brand
      contentful_id
      erpRating
      features
      fuelType
      kW
      manufacturerCountry
      name
      slug
      photo {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid
        }
        title
      }
      price
      shortDescription
      tagline
      warranty
      seo {
        image {
          file {
            url
          }
        }
        metaDescription {
          metaDescription
        }
        title
      }
    }
    extras: contentfulGlobalSettings {
      orderExtras
    }
  }
`
